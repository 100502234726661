import React from 'react'
import styled from 'styled-components'
// import { AppVideoBanner } from './AppVideoBanner'
import ContractLifecycleManagementBannerData from '../Data/ContractLifecycleManagementBannerData.json'
import { AppContractLifecycleManagementGraphicBanner } from './AppContractLifecycleManagementGraphicBanner'

const AppContractLifecycleManagementBannerWapper = styled.div`
  .ContractcontainSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 30px 50px 40px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1391px) {
      padding: 130px 20px 50px 20px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
    @media (max-width: 1920px) {
      height: 76vh;
    }

    @media (max-width: 1800px) {
      height: auto;
    }
  }

  .ContractBannerSectionOne {
    margin-top: 2rem;
    margin-bottom: auto;
    @media (max-width: 1390px) {
      margin-top: auto;
    }
  }
  .ContractBannerHeadingSize {
    max-width: 750px;
    line-height: 1.30435em;
    padding-bottom: 3%;
    font-size: 2.5rem;
    margin-top: auto;
  }
  .ContractBannerPara {
    max-width: 950px;
    padding: 10px 20px 0px 0px;
  }
  .ContractBannervideo {
    width: 65%;
    height: auto;
    @media (max-width: 1650px) {
      margin-top: 3%;
    }
    @media (max-width: 1390px) {
      width: 100%;
      margin-top: unset;
    }
  }
  .ContractBannerContent {
    margin-top: 3%;
    padding: 0px 110px 0px 0px;
    /* margin-right: 50px;
    margin-left: 50px; */
    @media (max-width: 1650px) {
      margin-top: unset;
    }
    @media (max-width: 1920px) {
      padding: 0px 230px 0px 0px;
    }

    @media (max-width: 1800px) {
      padding: 0px 60px 0px 0px;
    }
  }
  .ContractBannerImage {
    width: 100%;
    // height: auto;
    // float: right;
    // @media (max-width: 1390px) {
    //   width: 100%;
    //   float: unset;
    // }
  }
  .whyRaindropListView {
    display: none;
  }
  .ContractSectionButton {
    display: flex;
    justify-content: center;
    margin: 40px 0px 20px 0px;
  }
`

export const AppContractLifecycleManagementBanner = () => {
  return (
    <AppContractLifecycleManagementBannerWapper>
      {ContractLifecycleManagementBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppContractLifecycleManagementGraphicBanner
              containSection="ContractcontainSection"
              EventBannerHeading={data.heading}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              ImagePath={data.ImagePath}
              altName={data.AltName}
              ButtonStyle="ContractSectionButton"
              BannerSectionOne="ContractBannerSectionOne"
              BannerSectionTwo="BannerSectionTwo item-2"
              EventBannerHeadingSize="ContractBannerHeadingSize"
              EventBannerPara="ContractBannerPara"
              EventBannerImage="ContractBannerImage"
              UlStyle="whyRaindropListView"
              EventBannerSectionTwo="ContractBannervideo"
              EventBannerSectionOne="ContractBannerContent"
            />
          </div>
        )
      })}
    </AppContractLifecycleManagementBannerWapper>
  )
}
